import React, { useEffect, createRef } from "react"
import Layout from "../components/layout"
import lottie from "lottie-web"
import animation from "../animations/logodrop.json"

export default function Home() {
  let animationContainer = createRef()

  useEffect(() => {
    const anim = lottie.loadAnimation({
      container: animationContainer.current,
      renderer: "svg",
      loop: false,
      autoplay: true,
      animationData: animation,
    })
    return () => anim.destroy() // optional clean up for unmounting
  }, [])

  return (
    <Layout>
      <div className="container mx-auto flex items-center	flex-col px-4 mb-24">
        <div className=" w-full lg:w-5/12">
          <div className="animation-container" ref={animationContainer} />
        </div>
        <h1 className="font-serif text-5xl md:text-6xl lg:text-7xl mb-10">
          TofuTogether
        </h1>
        <div className="bg-black max-w-md p-6 text-white rounded-2xl border-8 border-white relative flex text-center content-center align-center flex-col">
        <h3 className="font-sans text-3xl md:text-4xl font-bold mb-4 text-center">
          Vegan & Vegetarian Dating App
        </h3>

        <a href="https://play.google.com/store/apps/details?id=com.tofutogether"  className="my-8 mx-8" target="_blank"><img src={'/google-play-badge.png'}  alt="TofuTogether Google App"/></a>
                <a href="https://apps.apple.com/gb/app/tofutogether/id1541949933" className="mx-8" target="_blank"><img src={'/apple-download-on-app-store.png'}  alt="TofuTogether Apple App"/></a>
                <p className="text-center text-5xl my-4 inline-block mt-12">🌱❤🌿</p>

        </div>
      </div>

      <div className="container mx-auto px-4 mb-24">
        <div class="grid grid-cols-1 md:grid-cols-2 gap-6">
          <div className="mb-8">
            <h1 className="font-sans text-2xl md:text-4xl font-bold mb-4">
              Dating and networking
            </h1>
            <p className="text-xl">
              Chat, date and make friends. Become part of a growing community to
              share what matters to you and fellow vegan and vegetarians.
            </p>
          </div>
          <div className="mb-8">
            <h1 className="font-sans text-2xl md:text-4xl font-bold mb-4">
              Connecting vegans and vegetarians
            </h1>
            <p className="text-xl">
              Find like-minded vegans and vegetarians, match, chat and date. Avoid the awkwardness and issues of having a different diet to your partner.
            </p>
          </div>
        </div>
      </div>
    </Layout>
  )
}
